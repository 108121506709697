export const AFFILIATE_STATISTICS = [
  {
    date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
    clicks: 10,
    registers: 5,
    ftds: 3,
    ftdsVolume: 1000,
    deposits: 8,
    depositsVolume: 2000,
    withdrawals: 2,
    withdrawalsVolume: 500,
    traders: 15,
    totalOperatedVolume: 5000,
    revenueShare: 100,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 6)).toISOString(),
    clicks: 8,
    registers: 3,
    ftds: 2,
    ftdsVolume: 800,
    deposits: 6,
    depositsVolume: 1500,
    withdrawals: 1,
    withdrawalsVolume: 300,
    traders: 10,
    totalOperatedVolume: 4000,
    revenueShare: 80,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 5)).toISOString(),
    clicks: 5,
    registers: 2,
    ftds: 1,
    ftdsVolume: 500,
    deposits: 4,
    depositsVolume: 1000,
    withdrawals: 0,
    withdrawalsVolume: 0,
    traders: 7,
    totalOperatedVolume: 3000,
    revenueShare: 50,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 4)).toISOString(),
    clicks: 10,
    registers: 5,
    ftds: 3,
    ftdsVolume: 1000,
    deposits: 8,
    depositsVolume: 2000,
    withdrawals: 2,
    withdrawalsVolume: 500,
    traders: 15,
    totalOperatedVolume: 5000,
    revenueShare: 100,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 3)).toISOString(),
    clicks: 8,
    registers: 3,
    ftds: 2,
    ftdsVolume: 800,
    deposits: 6,
    depositsVolume: 1500,
    withdrawals: 1,
    withdrawalsVolume: 300,
    traders: 10,
    totalOperatedVolume: 4000,
    revenueShare: 80,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 2)).toISOString(),
    clicks: 5,
    registers: 2,
    ftds: 1,
    ftdsVolume: 500,
    deposits: 4,
    depositsVolume: 1000,
    withdrawals: 0,
    withdrawalsVolume: 0,
    traders: 7,
    totalOperatedVolume: 3000,
    revenueShare: 50,
  },
  {
    date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
    clicks: 5,
    registers: 2,
    ftds: 1,
    ftdsVolume: 500,
    deposits: 4,
    depositsVolume: 1000,
    withdrawals: 0,
    withdrawalsVolume: 0,
    traders: 7,
    totalOperatedVolume: 3000,
    revenueShare: 50,
  },
];

export const INTERVALS = [
  {
    display: "Hoje",
    value: "today",
  },
  {
    display: "Últimas 24 horas",
    value: "24h",
  },
  {
    display: "Últimos 7 dias",
    value: "7d",
  },
  {
    display: "Últimos 30 dias",
    value: "30d",
  },
  {
    display: "Últimos 3 meses",
    value: "90d",
  },
];

export const intervalData = {
  today: {
    startDate: new Date(),
    granularity: "hour",
  },
  "24h": {
    startDate: new Date(Date.now() - 86400000),
    granularity: "hour",
  },
  "7d": {
    startDate: new Date(Date.now() - 604800000),
    granularity: "day",
  },
  "30d": {
    startDate: new Date(Date.now() - 2592000000),
    granularity: "day",
  },
  "90d": {
    startDate: new Date(Date.now() - 7776000000),
    granularity: "month",
  },
  all: {
    //Codeqr.io founding date
    startDate: new Date("2023-05-13"),
    granularity: "month",
  },
};

export const SYMBOLS_AVALIABLE = [
  {
    symbol: "BTCUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
  },
  {
    symbol: "ETHUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
  },
  {
    symbol: "SOLUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
  },
  {
    symbol: "XRPUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
  },
  {
    symbol: "ADAUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
  },
  {
    symbol: "IDXUSDT",
    type: "CRIPTOMOEDA",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  {
    symbol: "MEMXUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/MEMXUSDT.png",
  },
  { symbol: "BNBUSDT", type: "CRIPTOMOEDA", image: "" },
  {
    symbol: "TRUMPUSDT",
    type: "CRIPTOMOEDA",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/TRUMPUSDT.png",
  },
  {
    symbol: "EURUSD",
    type: "FOREX",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
  },
  {
    symbol: "GBPUSD",
    type: "FOREX",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
  },
  {
    symbol: "USDJPY",
    type: "FOREX",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
  },
  {
    symbol: "USDCHF",
    type: "FOREX",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
  },
];

export const SYMBOL_TYPE = ["TODOS ATIVOS", "CRIPTOMOEDA", "FOREX"];
